import logo from './logo.svg';
import './App.css';

import LoginPage from './components/loginPage/LoginPage';
import MainPage from './components/MainPage/MainPage';
import { useState } from 'react';

function App() {
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState(null);

  const isSecure = window.location.protocol === 'https:';

  if(!isSecure){
    return(
      <div>
        <p>Вы зашли по незащищенному протоколу, убедитесь что в ссылке указан https а не http, или перейдите по ссылке ниже</p>
        <a href='https://qr-service.kz/'>https://qr-service.kz/</a>
      </div>
    )
  }

  if(isLogged){
    return(<MainPage user={user}/>)
  }
  return (
    <LoginPage setIsLogged = {setIsLogged} setUser={setUser}/>
  );
}

export default App;
