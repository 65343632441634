import React, { useEffect, useState } from 'react';
import {
  CarOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons';
import { Layout, theme } from 'antd';
import PaymentStats from '../MyTable/MyTable.js';

const { Header, Sider, Content } = Layout;

const getMenuItems = (arr) =>{
    const result = [{
        key: '9',
        icon: <CarOutlined />,
        label: 'По всем боксам',
    }]
    arr.forEach(element => {
        result.push({
            key: element,
            icon: <CarOutlined />,
            label: 'Бокс ' + element,
        })
    });
    return(result)
}

const MainPage = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [count, setCount] = useState(0)

  useEffect(()=>{setCount(count+1);console.log(props)}, [props])

  return (
    <PaymentStats user={props.user}/>
  );
};
export default MainPage;
