import React, { useEffect } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Row, Col } from 'antd';
import { Card, Space } from 'antd';
import axios from 'axios';


function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
    let cookies = document.cookie;
    let parts = cookies.split(name + "=");
    if (parts.length === 2) {
        return parts.pop().split(";").shift();
    }
    return null;
}


const LoginPage = (props) => {

    useEffect(()=>{
        if(getCookie('username')){
            props.setIsLogged(true)
            console.log(getCookie('username'))
            props.setUser(getCookie('username'))
        }
    }, [])


  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    const fetchData = async () => {
        try {
          const response = await axios.get("https://qr-service.kz/CarFash/GetCarWashUser?password=" + values.password + "&login=" + values.username);
          console.log(response)
          if(response.data == "OK"){
            props.setIsLogged(true)
            setCookie('username', values.username, 7); // Сохраняет username на 7 дней
          }else{
            alert("Failed")
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
       
      };
      fetchData();
    // if (values.username == "Ruslan" && values.password == "123"){
    //     props.setIsLogged(true)
    // }else{
    //     alert("Failed")
    // }
  };
  return (
    <Row align={"middle"} justify={"center"} width={"100%"} style={{"height" : "100vh"}}> 
            <Card
            title="Авторизация"
            extra={""}
            style={{
            width: 500,
            }}
        >
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    >
                    <Form.Item
                        name="username"
                        rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите логин!',
                        },
                        ]}
                    >
                        <Input 
                        prefix={<UserOutlined className="site-form-item-icon" />} 
                        placeholder="Логин" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите пароль!',
                        },
                        ]}
                    >
                        <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Пароль"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Запомнить меня</Checkbox>
                        </Form.Item>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                        Войти
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
    </Row>
  );
};
export default LoginPage;